// #main .nav-link-container:nth-child(2).nav-link-container-active
& {
  color: #ffffff;
  h1, h2 {
    text-align: center;
    color: #8c1614;
    text-shadow: none;
  }
  h3 {
    text-align: center;
    color: #ffffff;
    text-shadow: none;
  }
}
