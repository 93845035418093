$grid-breakpoints: (
        xs: 0,
        sm: 768px,
        md: 992px,
        lg: 1300px,
        xl: 1400px
);


@import "import";
/*
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);
*/




//noinspection CssUnknownTarget
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600,700,900');

/**
  FARBEN
 */

/**
  GENERAL
 */

$body--font-family: 'Raleway';
$body--font-size: 15px;
$body--font-size-xs: 16px;
$body--font-weight: 300;
$body--font-color: #333;

$h1--color: #8c1614;
$h1--font-family: $body--font-family;
$h1--line-height: 42px;
$h1--text-transform: none;
$h1--font-size: 40px;
$h1--font-weight: 900;
$h1--padding: 0px 0px 5px 0px;
$h1--text-shadow: 1px 1px 0px #fff;


$h2--color: #8c1614;
$h2--font-family: $h1--font-family;
$h2--line-height: $h1--line-height;
$h2--text-transform: $h1--text-transform;
$h2--font-size: $h1--font-size;
$h2--font-size-xs: 30px;
$h2--font-weight: $h1--font-weight;
$h2--padding: $h1--padding;
$h2--text-shadow: $h1--text-shadow;

$h3--color: #706f6f;
$h3--font-family: $h1--font-family;
$h3--font-size: 25px;
$h3--font-size-xs: 17px;
$h3--font-weight: 600;
$h3--padding: 0px 0px 10px 0px;
$h3--text-shadow: 1px 1px 0px #fff;
$h3--line-height: 30px;

$font-size-h1: 48px;
$font-shadow-h1: 1px 1px 0 #fff;
$font-color-h1: #bb2731;
$font-weight-h1: 500;

$main--font-size-xs: 14px !important;

$font-size-h2: 18px;
$font-color-h2: #8e1a22;
$font-margin-h2: 0 0 10px 0;
$font-weight-h2: 700;

$font-color-h3: #bdd042;
$font-margin-h3: 4px 0 0 0;


$header-bg-verlauf-enabled: false;
$header--bg: #321c0c;
$header--height: 150px;

$main--padding: $header--height 0px 0px 0px;

$main__a--color: #000;
$main__a__hover--color: #ccc;



$hline-border-top: 1px solid rgba(0, 0, 0, 0.2);
$hline-border-bottom: 1px solid rgba(255, 255, 255, 0.7);


/**
 GENERAL IMPORT
 */
@import "variables";
@import "general/functions";
@import "general/basics";
//@import "header/navigation";
//@import "header/header";
//@import "header/logo";
@import "content/content";
//@import "general/schatten";
//@import "general/footer";
//@import "teaser/teaser";
//@import "teaser/flexslider";
@import "general/fontawesome/fontawesome";
$fa-font-path: '../fonts';

@import "general/fontawesome/fa-regular";
@import "general/fontawesome/fa-brands";
@import "general/fontawesome/fa-solid";
@import "modules/textblock";
//@import "modules/link-box";
@import "modules/downloads";

.textblock-devider {
  background: none !important;

  background-size: 100% !important;
  background-repeat: no-repeat !important;
  height: 30px !important;
  width: calc(100% + 30px) !important;
  position: relative;
  margin: 0px !important;
  left: -15px;
  border: 0px !important;
  bottom: -20px !important;

  img {
    width: 100%;
    vertical-align: bottom !important;
    bottom: 0px;
    position: absolute;
  }

}

/**
 * LAZYLOAD
 */
.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  width: calc(100% + 10px) !important;
  height: calc(100% + 10px) !important;
  max-width: unset !important;
  max-height: unset !important;
  top: -5px;
  left: -5px;
  transition: width 0.3s, height 0.3s, top 0.3s, left 0.3s, filter 0.3s;
  transition-timing-function: ease-in-out;
  position: absolute
}

.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
  width: 100% !important;
  height: 100% !important;
  top: 0px;
  left: 0px;
}

.cc-window {
  font-family: $body--font-family !important;
}






/**
  KESTHIEL
 */
$nav-link-width: 50px;

$bg-list: (
  #8c1614,
  #706f6f,
  #afaeae,
  #ffffff
);

$nav-color: (
  #ffffff,
  #ffffff,
  #ffffff,
  #000000
);



html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding:0;
  overflow: hidden;
}

strong, b {
  font-weight: 700;
}

a, a:focus, a:visited, a:link, a:active {
  color: #000;
}

aside.sidebar {
  @include gradient-y(#ffffff, #dddddd, 0%, 85%);
  padding: 0;
  .logo-container {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    width: 100%;

    background-image: url(../images/bg_left-min.c4bee654.png);
    background-position: center center;
    background-size: 100% auto;

    img.bg {
      width: 100%;
      max-width: 100%;
    }
  }
  > .content {
    position: relative;
    margin-top: 50vh;
    padding: 0 50px;
    .logo-img {
      max-width: 100%;
      position: absolute;
      width: 100%;
      transform: translateY(-100%);
      left: 0;
    }
  }
}

main.main-area {
  position: relative;
  padding: 0;
  .nav-link-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    width: unquote("calc(100% - #{(length($bg-list) - 1) * $nav-link-width})");

    display: flex;

    .nav-link {
      width: $nav-link-width;
      height: 100%;
      padding: 10px;
      position: relative;
      &.active {

      }
      .nav-link-bg {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        transform: rotateZ(180deg) translateX($nav-link-width);
        pointer-events: none;
      }
      a {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          transform: rotateZ(-90deg);
          display: block;
          white-space: nowrap;
        }
      }
    }
    .nav-article-content {
      position: relative;
      padding: 50px 50px 0;
      overflow-y: auto;
      width: unquote("calc(100% - 50px)");
    }

    &:nth-child(#{length($nav-color)}) .nav-link, &.nav-link-container--special .nav-link {
      &:before {
        border-right: 1px solid #cccccc;
        content: '';
        display: block;
        position: absolute;
        right: 0;
        height: unquote("calc(100% - #{$nav-link-width * 2})");
        top: $nav-link-height;
      }
    }
    @for $i from 3 to 7 {
      &:nth-child(#{$i}) {
        $i-margin-left: ($i - 3) * $nav-link-width;
        margin-left: $i-margin-left;
        background: nth($bg-list, ($i - 2));
        a span {
          color: nth($nav-color, ($i - 2));
        }
      }
    }
    &.nav-link-container--special {
      $i: 4;
      $i-margin-left: ($i - 1) * $nav-link-width;
      margin-left: $i-margin-left;
      background: nth($bg-list, $i);
      a span {
        color: nth($nav-color, $i);
      }
    }

    // HARDCODED
    &:nth-child(3) {
      @import "_sections/4_red";
    }
    &:nth-child(4) {
      @import "_sections/3_dark_grey";
    }
    &:nth-child(5) {
      @import "_sections/2_light_grey";
    }
    &:nth-child(6), &.nav-link-container--special {
      @import "_sections/1_white";
    }


    // Animation

    transition: transform 0.5s ease-in-out 0s;

    &.nav-link-container--active {
      & ~ .nav-link-container {
        transform: translateX(unquote("calc(100% - #{$nav-link-width})"));
        &.nav-link-container--special {
          transform: translateX(unquote("calc(100% + #{$nav-link-width*3})"));
        }
      }
    }
    &.mobile-only {
      display: none;
    }
  }
}

.allg-infos {
  text-align: center;
  p {
    span, &.allg-infos-links a {
      display: block;
    }
  }
}
.contacts {
  display: flex;
  justify-content: center;
  a, img {
    display: block;
    width: 30px;
    height: 30px;
  }
  a {
    margin-left: 10px;
  }
  a:nth-child(1) {
    margin-left: 0;
  }
}

$verlinkung-img--height: 150px;

.verlinkung-container {
  @include magicalImageContainer;
  margin: 10px;
  .verlinkung-pic {
    background: #cccccc;
    position: relative;
    height: $verlinkung-img--height;
    strong {
      position: absolute;
      left: -2px;
      bottom: 0;
      text-transform: uppercase;
      color: #fff;
      font-size: 30px;
      line-height: 0.7;
    }
  }
  .verlinkung-pic--img {
    height: 100%;
    width: 100%;
    overflow: hidden;
    img {
      max-width: 100%;
      transform: translateY(unquote('calc(-50% + #{$verlinkung-img--height / 2})'));
    }
  }
  .verlinkung-description {
    padding: 10px 10px 0 0;
    >p:nth-child(1) {
      margin: 0;
    }
  }
}

.mobile-nav, .mobile-only {
  display: none;
}

@include mobile {
  .mobile-only {
    display: inline;
  }
  body.row, html {
    margin: 0;
    overflow: auto;
    height: auto;
  }

  aside.sidebar {
    max-width: 100%;
    flex-basis: 100%;
    height: 100%;
    //min-height: 100vh;
    display: flex;
    flex-direction: column;
    >.logo-container {
      height: 70%;
    }
    >.content {
      margin-top: 0;
      padding: 0;
      height: 70%;
      .hline {
        margin-left: 50px;
        margin-right: 50px;
        width: unquote("calc(100% - 100px)");
      }
      .logo-img {
        position: relative;
        transform: none;
        left: auto;
        max-width: 70%;
        margin: auto;
      }
    }
    .mobile-nav {
      margin-top: 20px;
      height: 30%;
      min-height: 30vh;
      position: relative;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > a {
        width: 50%;

        min-height: 15vh;
        //height: 50%;
        padding: 10px;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-weight: 700;
        }

        @for $i from 1 to 5 {
          &:nth-child(#{5 - $i}) {
            background: nth($bg-list, $i);
            span {
              color: nth($nav-color, $i);
            }
          }
        }
      }
    }
  }






  main.main-area {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column-reverse;
    .nav-link-container {
      position: relative;
      width: 100%;
      margin-left: 0 !important;
      flex-direction: column;
      transform: none !important;
      height: auto;
      .nav-link {
        display: none;
      }
      .nav-article-content {
        width: 100%;
        padding: 20px 0;
        > article {
          padding-left: 0;
          padding-right: 0;
        }
      }

      &.nav-link-container--special {
        display: none;
      }
      &.mobile-only {
        display: block;
      }
    }
  }


  .textblock {
    .textblock-downloads {
      flex-basis: 50%;
      max-width: 50%;
      h2 {
        font-size: 14px !important;
        line-height: 17px;
      }
    }
  }

  .allg-infos {
    p {
      &.address {
        span {
          display: inline;
        }
      }
    }
    .wrapper {
      padding: 0 50px;
      display: flex;
      justify-content: space-between;
    }
  }
  @supports not (-webkit-overflow-scrolling: touch) {
    aside.sidebar {
      min-height: 100vh;
      .mobile-nav {
        margin-top: auto;
      }
    }
  }

  @supports (-webkit-overflow-scrolling: touch) {
    @media (orientation: landscape) {
      aside.sidebar {
        >.content {
          @include make-container();
          @include make-container-max-widths();
        }
      }
    }

  }

}
