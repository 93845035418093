// #main .nav-link-container:nth-child(3).nav-link-container-active
& {
  color: #ffffff;
  h1, h2 {
    text-align: center;
    text-shadow: none;
    color: #afaeae;
  }
  h3 {
    text-align: center;
    color: #ffffff;
    text-shadow: none;
  }
}
