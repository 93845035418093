.download-file {
  padding: 0 15px;
  a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  h2 {
    font-size: 14px;
    margin-bottom: 4px;
  }
  span {
    width: 100%;
    margin-top: 0;
    padding-top: 4px;
    text-align: center;
    display: block;
    margin-bottom: 30px;
  }
}

/*@include mobile() {
  .download-section .download-file {
    width: 100%;
  }
}
*/
