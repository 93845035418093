@mixin transition-ani($conf) {
  transition: $conf;
  -webkit-transition: $conf;
  -moz-transition: $conf;
  -o-transition: $conf;
}

@mixin grayscale($percent) {
  filter: grayscale($percent);
  -webkit-filter: grayscale($percent);
  -moz-filter: grayscale($percent);
  -o-filter: grayscale($percent);
  -ms-filter: grayscale($percent);
}

@mixin magicalImageContainer($padding: 10px, $background: #fff, $boxShadow: 0 0 10px rgba(0, 0, 0, 0.25)) {
  padding: $padding;
  box-shadow: $boxShadow;
  background: $background;
}

@mixin magicalGrayscale($hoverEffect: true) {
  transition: filter 0.3s ease-in-out 0s;
  filter: grayscale(100%);
  @if $hoverEffect == true {
    &:hover {
      filter: grayscale(0%);
    }
  }
}

@mixin smoothHover($color) {
  @include transition-ani(color 0.4s ease-in-out 0s);
  &:hover {
    color: $color;
  }
}

@mixin linear-gradient($c1, $c2, $c3, $c4) {
  background: -moz-linear-gradient(left, $c1 0%, $c2 25%, $c3 50%, $c2 75%, $c4 100%);
  background: -ms-linear-gradient(left, $c1 0%, $c2 25%, $c3 50%, $c2 75%, $c4 100%);
  background: -webkit-linear-gradient(left, $c1 0%, $c2 25%, $c3 50%, $c2 75%, $c4 100%);
  background: -webkit-gradient(linear, 0 0, 100% 0, from($c1), color-stop(0.25, $c2), color-stop(0.50, $c3), color-stop(0.75, $c2), to($c4));
  background: -o-linear-gradient(left, $c1 0%, $c2 25%, $c3 50%, $c2 75%, $c4 100%);
  background: linear-gradient(left, $c1 0%, $c2 25%, $c3 50%, $c2 75%, $c4 100%);
}

@mixin linear-gradient-3($c1, $c2, $c3) {
  background: -moz-linear-gradient(left, $c1 0%, $c2 50%, $c3 100%);
  background: -ms-linear-gradient(left, $c1 0%, $c2 50%, $c3 100%);
  background: -webkit-linear-gradient(left, $c1 0%, $c2 50%, $c3 100%);
  background: -webkit-gradient(linear, 0 0, 100% 0, from($c1), color-stop(0.50, $c2), to($c3));
  background: -o-linear-gradient(left, $c1 0%, $c2 50%, $c3 100%);
  background: linear-gradient(left, $c1 0%, $c2 50%, $c3 100%);
}

@mixin linear-gradient-3($c1, $c2, $c3) {
  background: -moz-linear-gradient(left, $c1 0%, $c2 50%, $c3 100%);
  background: -ms-linear-gradient(left, $c1 0%, $c2 50%, $c3 100%);
  background: -webkit-linear-gradient(left, $c1 0%, $c2 50%, $c3 100%);
  background: -webkit-gradient(linear, 0 0, 100% 0, from($c1), color-stop(0.50, $c2), to($c3));
  background: -o-linear-gradient(left, $c1 0%, $c2 50%, $c3 100%);
  background: linear-gradient(to right, $c1 0%, $c2 50%, $c3 100%);
}

@mixin linear-gradient-2-vert($c1, $c2) {
  background: -moz-linear-gradient(top, $c1 0%, $c2 100%);
  background: -ms-linear-gradient(top, $c1 0%, $c2 100%);
  background: -webkit-linear-gradient(top, $c1 0%, $c2 100%);
  background: -o-linear-gradient(top, $c1 0%, $c2 100%);
  background: linear-gradient(top, $c1 0%, $c2 100%);
  background: -webkit-gradient(linear, 0 0, 0 100%, from($c1), to($c2));
}

@mixin linear-gradient-3-pos($c1,$c2,$c3,$posC1:0%,$posC2:50%,$posC3:100%) {
  background: -moz-linear-gradient(left, $c1 $posC1, $c2 $posC2, $c3 $posC3);
  background: -ms-linear-gradient(left, $c1 $posC1, $c2 $posC2, $c3 $posC3);
  background: -webkit-linear-gradient(left, $c1 $posC1, $c2 $posC2, $c3 $posC3);
  //background: -webkit-gradient(linear, 0 0, 100% 0, from($c1), color-stop(0.50, $c2), to($c3));
  background: -o-linear-gradient(left, $c1 $posC1, $c2 $posC2, $c3 $posC3);
  background: linear-gradient(to right, $c1 $posC1, $c2 $posC2, $c3 $posC3);
}

@mixin linear-gradient-2-vert-pos($c1,$c2,$posC1,$posC2) {
  background: -moz-linear-gradient(top, $c1 $posC1, $c2 $posC2);
  background: -ms-linear-gradient(top, $c1 $posC1, $c2 $posC2);
  background: -webkit-linear-gradient(top, $c1 $posC1, $c2 $posC2);
  background: -o-linear-gradient(top, $c1 $posC1, $c2 $posC2);
  background: linear-gradient(to bottom, $c1 $posC1, $c2 $posC2);
  //background: -webkit-gradient(linear, 0 0, 0 100%, from($c1), to($c2));
}

@mixin linear-gradient-5-pos($c1,$c2,$c3,$c4,$c5, $posC1:0%, $posC2:25%, $posC3: 50%, $posC4: 75%, $posC5: 100%) {
  background: -moz-linear-gradient(left, $c1 $posC1, $c2 $posC2, $c3 $posC3, $c4 $posC4, $c5 $posC5);
  background: -ms-linear-gradient(left, $c1 $posC1, $c2 $posC2, $c3 $posC3, $c4 $posC4, $c5 $posC5);
  background: -webkit-linear-gradient(left, $c1 $posC1, $c2 $posC2, $c3 $posC3, $c4 $posC4, $c5 $posC5);
  background: -o-linear-gradient(left, $c1 $posC1, $c2 $posC2, $c3 $posC3, $c4 $posC4, $c5 $posC5);
  background: linear-gradient(to right, $c1 $posC1, $c2 $posC2, $c3 $posC3, $c4 $posC4, $c5 $posC5);
}

@mixin hline() {
  border-bottom: $hline-border-bottom;
  border-top: $hline-border-top;
  margin: $hline-margin;
}

@mixin bg-image($img, $repeat: repeat-x, $position: 50% center) {
  background-image: $img;
  background-repeat: $repeat;
  background-position: $position;
}

@mixin headline($from: 1, $to: 6, $prefix: '') {
  @for $i from $from through $to {
    #{$prefix} h#{$i} {
      @content
    }
  }
}

@mixin listing-box($className, $direction: row, $justfiyContent: center, $align-items: center, $flex-wrap: nowrap) {
  .#{$className}--listing {
    display: flex;
    flex-direction: $direction;
    justify-content: $justfiyContent;
    align-items: $align-items;
    flex-wrap: $flex-wrap;

    .#{$className} {
      @content
    }
  }
}

@mixin text-fixed-bottom($height: 20px) {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $height;
  @content
}

@mixin top-left-corner($left: 20px, $top: 20px, $width: 30px, $height: 30px) {
  position: absolute;
  left: $left;
  top: $top;
  width: $width;
  height: $height;
}

@mixin schatten-fixed-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  //transform: translateY(-100%);
  z-index: 1;
  img {
    vertical-align: bottom;
    max-width: 100%;
    width: 100%;
  }
}

@mixin schatten-fixed-bottom-translate {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  z-index: 1;
  img {
    vertical-align: bottom;
    max-width: 100%;
    width: 100%;
  }
}

@mixin global-img-properties-without-tag() {
  max-width: 100%;
  max-height: 100%;
}

@mixin global-img-properties() {
  img {
    @include global-img-properties-without-tag();
  }
}

@mixin vCol($margin: 10px, $wrap4: false) {
  &.vCol-1 {
    width: unquote("calc(100% - #{$margin})");
  }
  &.vCol-2 {
    width: unquote("calc(50% - #{$margin})");
    &:nth-child(odd) {
      margin-left: 0;
      margin-right: $margin;
    }
    &:nth-child(even) {
      margin-right: 0;
      margin-left: $margin;
    }
  }
  &.vCol-3 {
    width: unquote("calc(33.33% - #{$margin})");
  }

  &.vCol-4 {
    @if $wrap4 == false {
      width: unquote("calc(50% - #{$margin})");
      &:nth-child(odd) {
        margin-left: 0;
        margin-right: $margin;
      }
      &:nth-child(even) {
        margin-right: 0;
        margin-left: $margin;
      }
    } @else {
      width: unquote("calc(25% - #{$margin})");
    }
  }
}

@mixin link-button-square($bg: #fff, $size: 50px, $imgSize: 40px) {
  a {
    display: block;
    background: $bg;
    max-width: $size;
    max-height: $size;
    height: $size;
    width: $size;
    padding: ($size - $imgSize) / 2;
  }
  img {
    max-width: $imgSize;
    max-height: $imgSize;
    height: $imgSize;
    width: $imgSize;
  }
}

@mixin flex($direction: row,$justifyContent: space-around,$alignItems: inherit,$wrap: inherit) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-wrap: $wrap;
}

@mixin size-16by9() {
  &::before {
    content: ' ';
    margin-top: 56.25%;
  }
}

@mixin listing-box-mobile($selectors) {
  @media screen and (max-width: $screen-xs-max), screen and (min-device-pixel-ratio: 1.5) and (max-width: $screen-sm-max), screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $screen-sm-max) {
    @each $item in $selectors {
      .#{$item}--listing {
        .#{$item} {
          width: 100%;
          @content
        }
      }
    }
  }
}

@mixin custom-colorset-for-children($list, $transparency: 1) {
  @for $i from 1 through length($list) {
    $c : nth($list, $i);
    &:nth-child(#{$i}) {
      background: rgba($c,$transparency);
      .dropdown-menu {
        li a {
          &:hover {
            color: $c;
          }
        }
      }
    }
  }
}


@mixin children-custom-property($list, $property, $selectorBefore: '', $selectorAfter: '') {
  @for $i from 1 through length($list) {
    #{$selectorBefore} &:nth-child(#{$i})#{$selectorAfter} {
      #{$property}: nth($list,$i);
    }
  }
}

@mixin children-bg-colorset($list) {
  @include children-custom-property($list,'background');
}

@mixin custom-shadow-for-children($list) {
  @include children-custom-property($list,'text-shadow','',' > a');
}
@mixin custom-hover-for-children($list) {
  @include children-custom-property($list,'color','',' > a:hover');
  @include children-custom-property($list,'color','',' > .dropdown-menu > li > a:hover');
  @include children-custom-property($list,'color','','.active > a:hover');
}
@mixin custom-color-for-children($list) {
  @include children-custom-property($list,'color','','> a');
}
@mixin custom-active-for-children($list) {
  @include children-custom-property($list,'color','','.active > a');
}


@mixin mobile() {
  @media
  only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2), // iPhone 4 und 4S
  only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2), // iPhone 5 und 5S
  only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2), // iPhone 6
  only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3), // iPhone 6+
  screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2), // Galaxy S3
  screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3), // Galaxy S4
  screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3), // Galaxy S5
  screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3), // HTC One
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1), // iPad mini
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1), // iPad 1 und 2
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2), // iPad 3 und 4
    //(min-device-width: 800px) and (max-device-width: 1280px), // Galaxy Tab 10.1
  screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332), // Asus Nexus 7
    //only screen and (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5), // Kindle Fire HD 7"
    //only screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5), // Kindle Fire HD 8.9"

  screen and (max-width: map_get($grid-breakpoints, 'md') - 1px),
  screen and (min-device-pixel-ratio: 1.5) and (max-width: map_get($grid-breakpoints, 'md') - 1px),
  screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: map_get($grid-breakpoints, 'md') - 1px)
  {
    @content
  }
}
